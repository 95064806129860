import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import square from "../../assets/images/info/img-square.svg";
import day1Ko from "../../assets/images/info/day1ver4-ko.svg";
import day2Ko from "../../assets/images/info/day2ver4-ko.svg";
import day1En from "../../assets/images/info/day1ver4-en.svg";
import day2En from "../../assets/images/info/day2ver4-en.svg";
import time from "../../assets/images/info/img-time.svg";
import blur from "../../assets/images/info/box-blur.png";
import calendar from "../../assets/images/info/ico-calendar-b.svg";
import { useEffect, useRef, useState } from "react";
import GuideMap from "./GuideMap";
import { Helmet } from "react-helmet";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import Titles from "../../components/Title";
import Dividers from "../../components/Dividers";
import Schedule from "./Schedule";
import { Link, useLocation } from "react-router-dom";
import { applyTypography } from "../../styles/mixins";
import axios from "axios";
import { APIURL } from "../../constants";

type Program = {
    id: number;
    titleKo: string;
    titleEn: string;
    date: string;
    startTime: string;
    endTime: string;
    placeKo: string;
    placeEn: string;
    positionKo: string;
    positionEn: string;
    contentKo: string;
    contentEn: string;
    category: string;
};

type PositionData = {
    positionKo: string;
    positionEn: string;
    programs: Program[];
};
const startTime = 9; // 시작 시간 (09:00)
const totalHours = 21; // 09:00 ~ 다음날 06:00까지 총 21시간

const TimelineGrid = () => {
    // 30분 간격으로 시간 라벨 생성
    const timeSlots = [];
    for (let i = 0; i <= totalHours * 2; i++) {
        const hours = Math.floor(i / 2) + startTime;
        const actualHours = hours >= 24 ? hours - 24 : hours;
        const minutes = i % 2 === 0 ? "00" : "30";
        timeSlots.push({
            timeLabel: minutes === "00" ? `${actualHours < 10 ? "0" : ""}${actualHours}:00` : "",
            isDotted: i % 2 === 1, // 홀수이면 점선
        });
    }

    return (
        <TimelineContainer>
            {timeSlots.map((slot, index) => (
                <TimeSlot key={index} isDotted={slot.isDotted}>
                    <TimeLabel>{slot.timeLabel}</TimeLabel>
                </TimeSlot>
            ))}
        </TimelineContainer>
    );
};

const Timeline = () => {
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [data, setData] = useState<PositionData[]>([]);
    const location = useLocation();

    // Retrieve the `date` query parameter from the URL
    const queryParams = new URLSearchParams(location.search);
    const date = queryParams.get("date");
    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
        const fetchData = async () => {
            try {
                const response = await axios.get(`${APIURL}/program/timeline?date=${date}`);
                console.log(response.data.data);
                setData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [date]);

    const calculateTimeDifference = (startTime: string, endTime: string) => {
        const [startHours, startMinutes] = startTime.split(":").map(Number);
        const [endHours, endMinutes] = endTime.split(":").map(Number);
        const startTotalMinutes = startHours * 60 + startMinutes;
        const endTotalMinutes = endHours * 60 + endMinutes;

        return endTotalMinutes - startTotalMinutes;
    };

    const calculateMarginTop = (startTime: string) => {
        const [hours, minutes] = startTime.split(":").map(Number);
        const totalMinutes = hours * 60 + minutes;
        const startMinutes = 9 * 60; // Starting point at 09:00
        const difference = totalMinutes - startMinutes;
        return (difference / 30) * 40; // Scaling each 30 minutes to 40px
    };

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-Timeline`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-타임라인`}</title>
                </Helmet>
            )}
            <Navibar />
            <Wrap>
                <PaddingWrap>
                    <Title>{activeLanguage === "en" ? "Timeline" : "전체 타임라인"}</Title>
                    <Divider />
                    <Contents>
                        <TopMenu>
                            <StyleLink to={`?date=${"2024-12-28"}`}>
                                <div className="dayBox">
                                    <img src={calendar} />
                                    <div className="day">Day 01</div>
                                </div>
                                <div className="date">
                                    {activeLanguage === "en" ? "2024-12-28 (Sat)" : "2024-12-28 (토)"}
                                </div>
                            </StyleLink>
                            <StyleLink to={`?date=${"2024-12-29"}`}>
                                <div className="dayBox">
                                    <img src={calendar} />
                                    <div className="day">Day 02</div>
                                </div>
                                <div className="date">
                                    {activeLanguage === "en" ? "2024-12-29 (Sun)" : "2024-12-29 (일)"}
                                </div>
                            </StyleLink>
                            <StyleLink to={`?date=${"2024-12-30"}`}>
                                <div className="dayBox">
                                    <img src={calendar} />
                                    <div className="day">Day 03</div>
                                </div>
                                <div className="date">
                                    {activeLanguage === "en" ? "2024-12-30 (Mon)" : "2024-12-30 (월)"}
                                </div>
                            </StyleLink>
                            <StyleLink to={`?date=${"2024-12-31"}`}>
                                <div className="dayBox">
                                    <img src={calendar} />
                                    <div className="day">Day 04</div>
                                </div>
                                <div className="date">
                                    {activeLanguage === "en" ? "2024-12-31 (Thu)" : "2024-12-31 (화)"}
                                </div>
                            </StyleLink>
                        </TopMenu>
                        {data.length > 0 ? (
                            <DataContainer>
                                {data.map((position) => {
                                    return (
                                        <ScheduleContainer>
                                            <ScheduleTitle
                                                color={
                                                    position.positionKo === "아트홀"
                                                        ? "#c30d23"
                                                        : position.positionKo === "디자인 랩"
                                                        ? "#036eb8"
                                                        : "#fed800"
                                                }
                                            >
                                                {activeLanguage === "en" ? position.positionEn : position.positionKo}
                                            </ScheduleTitle>
                                            <ScheduleBox>
                                                {position.programs.map((value) => {
                                                    const height =
                                                        (calculateTimeDifference(value.startTime, value.endTime) / 30) *
                                                        40;
                                                    const marginTop = calculateMarginTop(value.startTime);

                                                    return (
                                                        <ScheduleList
                                                            key={value.id}
                                                            color={
                                                                position.positionKo === "아트홀"
                                                                    ? "#c30d23"
                                                                    : position.positionKo === "디자인 랩"
                                                                    ? "#036eb8"
                                                                    : "#fed800"
                                                            }
                                                            height={height}
                                                            marginTop={marginTop}
                                                        >
                                                            <p className="place">
                                                                {activeLanguage === "en"
                                                                    ? value.placeEn
                                                                    : value.placeKo}
                                                            </p>
                                                            <Link
                                                                to={`/${activeLanguage}/${value.category}/${value.id}`}
                                                            >
                                                                <div className="content">
                                                                    {activeLanguage === "en"
                                                                        ? value.titleEn
                                                                        : value.titleKo}
                                                                </div>
                                                            </Link>
                                                        </ScheduleList>
                                                    );
                                                })}
                                            </ScheduleBox>
                                        </ScheduleContainer>
                                    );
                                })}
                            </DataContainer>
                        ) : (
                            <p>데이터를 불러오는 중입니다...</p>
                        )}
                        {<TimelineGrid></TimelineGrid>}
                    </Contents>
                </PaddingWrap>
            </Wrap>
            <Footer />
        </>
    );
};

export default Timeline;

const Wrap = styled(Wraps)``;
const PaddingWrap = styled(PaddingWraps)``;

const Title = styled(Titles)``;

const Divider = styled(Dividers)`
    margin-bottom: 8px;
`;

const Contents = styled.div`
    position: relative;
    height: 2300px;
`;
const fadeInUpAnimation = `
    @keyframes fadeInUp2 {
        from { opacity: 0; transform: translateY(3%); }
        to { opacity: 1; transform: translateY(0); }
    }

    .custom-fadeInUp2 {
        animation: fadeInUp2 0.5s ease-in-out;
    }
`;
const TopMenu = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`;
const StyleLink = styled(Link)`
    display: flex;
    text-decoration: none;
    flex-direction: column;
    align-items: center;
    border-bottom: solid 4px #000;
    margin-top: 16px;
    width: 48%;
    .dayBox {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .day {
        ${applyTypography("primary", 3)};
    }
    .date {
        margin-top: 8px;
        ${applyTypography("secondary", 1)};
        padding-bottom: 24px;
    }

    @media (min-width: 1440px) {
        width: 24%;
    }
`;

const DataContainer = styled.div`
    display: flex;
    margin-top: 44px;
    margin-left: 90px;
`;

const ScheduleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20px;
`;
const ScheduleTitle = styled.div<{ color?: string }>`
    ${applyTypography("primary", 4)};
    height: 44px;
    background-color: ${({ color }) => color};
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ScheduleBox = styled.div`
    display: flex;
    gap: 20px;
`;

const ScheduleList = styled.div<{ color?: string; height: number; marginTop: number }>`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .place {
        display: flex;
        ${applyTypography("secondary", 0)};
    }
    .content {
        ${applyTypography("secondary", 2)};
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        border: solid 4px ${({ color }) => color};
        box-sizing: border-box;
        height: ${({ height }) => `${height}px`};
        position: relative;
        background-color: #ffffff;
        z-index: 1;
        margin-top: ${({ marginTop }) => `${20 + marginTop}px`};
    }
`;
const TimelineContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    background-color: #ffffff;
    margin-left: 70px;
    position: absolute;
    top: 349px;
    @media (min-width: 768px) {
        top: 355px;
    }
    @media (min-width: 1440px) {
        top: 254px;
    }
`;

const TimeSlot = styled.div<{ isDotted: boolean }>`
    height: 40px; /* 30분 간격 높이 */
    border-top: ${(props) => (props.isDotted ? "1px dotted #555" : "1px solid #555")};
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
`;

const TimeLabel = styled.span`
    color: #000000;
    font-size: 14px;
    position: absolute;
    left: -55px;
    top: -7px;
`;
