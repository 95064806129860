import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import Dividers from "../../components/Dividers";
import { applyTypography } from "../../styles/mixins";
import { Link } from "react-router-dom";
import b2_1_art from "../../assets/images/info/b2_1_art.png";
import b2_c1_art from "../../assets/images/info/b2_c1_art.png";
import b2_c2_art from "../../assets/images/info/b2_c2_art.png";
import b2_c4_art from "../../assets/images/info/b2_c4_art.png";
import b2_c5_art from "../../assets/images/info/b2_c5_art.png";
import b2_m1_art from "../../assets/images/info/b2_m1_art.png";
import b2_c1_design from "../../assets/images/info/b2_c1_design.png";
import b2_c2_design from "../../assets/images/info/b2_c2_design.png";
import b2_c3_design from "../../assets/images/info/b2_c3_design.png";
import b2_a1_design from "../../assets/images/info/b2_a1_design.png";
import b2_a2_market from "../../assets/images/info/b2_a2_market.png";
import f1_a3a4_art from "../../assets/images/info/f1_a3a4_art.png";
import f1_a4_market from "../../assets/images/info/f1_a4_market.png";
import f1_d_design from "../../assets/images/info/f1_d_design.png";
import f1_market_mac from "../../assets/images/info/f1_market_mac.png";
import f1_a3_mac from "../../assets/images/info/f1_a3_mac.png";
import toilet from "../../assets/images/info/toilet.png";
import elev from "../../assets/images/info/elev.png";
import bag from "../../assets/images/info/bag.png";
import info from "../../assets/images/info/info.png";
import es from "../../assets/images/info/es.png";
import paking from "../../assets/images/info/paking.png";
import Titles from "../../components/Title";

const Maps = () => {
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [firstSelect, setFirstSelect] = useState<string>("b2f");
    const [secondSelect, setSecondSelect] = useState<string>("지하철1번출구");
    const [thirdSelect, setThirdSelect] = useState<string>("아트홀");
    const [secondOptions, setSecondOptions] = useState<string[]>([]);
    const [thirdOptions, setThirdOptions] = useState<string[]>([]);
    const [imagePath, setImagePath] = useState<string>("");
    const [isSecondSelectDisabled, setIsSecondSelectDisabled] = useState<boolean>(false);

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
    }, []);

    useEffect(() => {
        const options: { [key: string]: string[] } = {
            b2f: [
                "지하철1번출구",
                "출입구C1",
                "출입구C2",
                // "출입구C3",
                "출입구C4",
                "출입구C5",
                "출입구M1",
                // "출입구A1",
                // "출입구A2",
            ],
            f1: ["출입구A3", "이간수문전시장우측통로"],
        };

        // if (firstSelect) {
        //     const newSecondOptions = options[firstSelect] || [];
        //     setSecondOptions(newSecondOptions);
        //     setSecondSelect(newSecondOptions[0] || ""); // 기본값 설정
        // }

        if (firstSelect) {
            let newSecondOptions = options[firstSelect] || [];

            // 첫 번째 select가 b2f이고 세 번째 select가 디자인랩일 때 필터링된 옵션을 설정
            if (firstSelect === "b2f" && thirdSelect === "디자인랩") {
                newSecondOptions = ["출입구C1", "출입구C2", "출입구C3", "출입구A1"];
            } else if (firstSelect === "b2f" && thirdSelect === "이간수문전시장") {
                newSecondOptions = ["출입구"];
            }

            setSecondOptions(newSecondOptions);

            // 첫 번째 select가 변경될 때 두 번째 select를 첫 번째 옵션으로 설정
            if (newSecondOptions.length > 0) {
                setSecondSelect(newSecondOptions[0]);
            }
        }
    }, [firstSelect, thirdSelect]);

    useEffect(() => {
        const options: { [key: string]: string[] } = {
            지하철1번출구: ["아트홀", "디자인랩", "이간수문전시장"],
            출입구C1: ["아트홀", "디자인랩", "이간수문전시장"], //아, 디
            출입구C2: ["아트홀", "디자인랩", "이간수문전시장"], //아, 디
            출입구C3: ["아트홀", "디자인랩", "이간수문전시장"], //아, 디
            출입구C4: ["아트홀", "디자인랩", "이간수문전시장"],
            출입구C5: ["아트홀", "디자인랩", "이간수문전시장"],
            출입구M1: ["아트홀", "디자인랩", "이간수문전시장"],
            출입구A1: ["아트홀", "디자인랩", "이간수문전시장"], //아, 디
            출입구: ["아트홀", "디자인랩", "이간수문전시장"],
            출입구A3: ["맥스타일", "아트홀", "디자인랩", "이간수문전시장"], //1f
            이간수문전시장우측통로: ["맥스타일", "아트홀", "디자인랩", "이간수문전시장"], //1f
        };

        // if (secondSelect) {
        //     const newThirdOptions = options[secondSelect] || [];
        //     setThirdOptions(newThirdOptions);
        //     setThirdSelect(newThirdOptions[0] || ""); // 기본값 설정
        // }
        if (firstSelect === "f1" && (secondSelect === "출입구A3" || secondSelect === "이간수문전시장우측통로")) {
            // 첫 번째 select가 f1일 때 세 번째 select의 옵션을 특정 값으로 설정
            setThirdOptions(["맥스타일", "아트홀", "디자인랩", "이간수문전시장"]);
        } else if (secondSelect) {
            const newThirdOptions = options[secondSelect] || [];
            setThirdOptions(newThirdOptions);
            if (!newThirdOptions.includes(thirdSelect)) {
                setThirdSelect(newThirdOptions[0] || ""); // 필요할 때만 기본값 설정
            }
        }
    }, [firstSelect, secondSelect]);

    useEffect(() => {
        const images: { [key: string]: { [key: string]: string } } = {
            지하철1번출구: {
                아트홀: b2_1_art,
            },
            출입구C1: {
                아트홀: b2_c1_art,
                디자인랩: b2_c1_design,
            },
            출입구C2: {
                아트홀: b2_c2_art,
                디자인랩: b2_c2_design,
            },
            출입구C3: {
                디자인랩: b2_c3_design,
            },
            출입구C4: {
                아트홀: b2_c4_art,
            },
            출입구C5: {
                아트홀: b2_c5_art,
            },
            출입구M1: {
                아트홀: b2_m1_art,
            },
            출입구A1: {
                디자인랩: b2_a1_design,
            },
            출입구: {
                이간수문전시장: b2_a2_market,
            },
            출입구A3: {
                아트홀: f1_a3a4_art,
                디자인랩: f1_d_design,
                이간수문전시장: f1_a4_market,
                맥스타일: f1_a3_mac,
            },
            이간수문전시장우측통로: {
                아트홀: f1_a3a4_art,
                디자인랩: f1_d_design,
                이간수문전시장: f1_a4_market,
                맥스타일: f1_market_mac,
            },
        };

        if (secondSelect && thirdSelect) {
            setImagePath(images[secondSelect]?.[thirdSelect] || "");
        }
    }, [secondSelect, thirdSelect]);

    // 두 번째 select를 비활성화하는 조건 설정
    useEffect(() => {
        if (
            (secondSelect === "출입구A3" || secondSelect === "이간수문전시장우측통로") &&
            ["아트홀", "디자인랩", "이간수문전시장"].includes(thirdSelect)
        ) {
            setIsSecondSelectDisabled(true);
        } else if (firstSelect === "b2f" && secondSelect === "출입구" && thirdSelect === "이간수문전시장") {
            setIsSecondSelectDisabled(true);
        } else {
            setIsSecondSelectDisabled(false);
        }
    }, [secondSelect, thirdSelect]);

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-Maps`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-가이드맵`}</title>
                </Helmet>
            )}
            <Navibar />
            <Wrap>
                <PaddingWrap>
                    <Title>{activeLanguage === "en" ? "Guide Map" : "가이드맵"}</Title>
                    <Divider />
                    <Container>
                        <SelectBox>
                            <label htmlFor="first-select">{activeLanguage === "en" ? `Floor` : `층 선택`}</label>
                            <select
                                id="first-select"
                                value={firstSelect}
                                onChange={(e) => setFirstSelect(e.target.value)}
                            >
                                <option value="b2f">B2F(어울림광장)</option>
                                <option value="f1">1F(미래로, 대로변)</option>
                            </select>
                        </SelectBox>
                        <SelectBox disabled={isSecondSelectDisabled}>
                            <label htmlFor="second-select" className="start">
                                {activeLanguage === "en" ? `Starting point` : `출발지`}
                            </label>
                            <select
                                id="second-select"
                                value={secondSelect}
                                onChange={(e) => setSecondSelect(e.target.value)}
                                disabled={isSecondSelectDisabled}
                            >
                                {secondOptions.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </SelectBox>
                        <SelectBox>
                            <label htmlFor="third-select"> {activeLanguage === "en" ? `Destination` : `도착지`}</label>
                            <select
                                id="third-select"
                                value={thirdSelect}
                                onChange={(e) => setThirdSelect(e.target.value)}
                            >
                                {thirdOptions.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </SelectBox>
                    </Container>

                    {imagePath && (
                        <ImageBox>
                            <img src={imagePath} alt="선택한 위치의 이미지" style={{ width: "100%" }} />
                            <p className="source">지도 출처: 서울디자인재단</p>
                            <MapIcon>
                                <div className="map-icon">
                                    <img className="icon" src={toilet} alt="icon" />
                                    <p className="info">{activeLanguage === "en" ? "Restroom" : "화장실"}</p>
                                </div>
                                <div className="map-icon">
                                    <img className="icon" src={elev} alt="icon" />
                                    <p className="info">{activeLanguage === "en" ? "Elevator" : "엘리베이터"}</p>
                                </div>
                                <div className="map-icon">
                                    <img className="icon" src={bag} alt="icon" />
                                    <p className="info">{activeLanguage === "en" ? "Locker" : "물품보관함"}</p>
                                </div>
                                <div className="map-icon">
                                    <img className="icon" src={info} alt="icon" />
                                    <p className="info">{activeLanguage === "en" ? "Information Desk" : "안내소"}</p>
                                </div>
                                <div className="map-icon">
                                    <img className="icon" src={es} alt="icon" />
                                    <p className="info">{activeLanguage === "en" ? "Escalator" : "에스컬레이터"}</p>
                                </div>
                                <div className="map-icon">
                                    <img className="icon" src={paking} alt="icon" />
                                    <p className="info">{activeLanguage === "en" ? "Parking lot" : "주차장"}</p>
                                </div>
                            </MapIcon>
                        </ImageBox>
                    )}
                </PaddingWrap>
            </Wrap>
            <Footer />
        </>
    );
};

export default Maps;

const Wrap = styled(Wraps)``;

const PaddingWrap = styled(PaddingWraps)``;

const Title = styled(Titles)``;

const Divider = styled(Dividers)``;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 768px) {
        flex-wrap: wrap;
        flex-direction: row;
    }
    @media (min-width: 1440px) {
        justify-content: center;
        gap: 30px;
    }
`;
const SelectBox = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    position: relative;

    @media (min-width: 768px) {
        width: 46%;
    }

    @media (min-width: 1440px) {
        width: 25%;
    }
    label {
        ${applyTypography("primary", 4)};
        width: 20%;
        @media (min-width: 768px) {
            width: 25%;
        }
    }

    &::after {
        content: "▼";
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: 14px;
        color: ${({ disabled }) => (disabled ? "#a3a3a3" : "black")};
    }

    select {
        box-sizing: border-box;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: white;
        /* border: 4px solid black; */
        border-radius: 0;
        padding: 10px 40px 10px 10px;
        width: 100%;
        cursor: pointer;
        ${applyTypography("secondary", 3)};
        border: 4px solid ${({ disabled }) => (disabled ? "#a3a3a3" : "black")};

        &:focus {
            outline: none;
            border-color: #000;
        }
        @media (min-width: 768px) {
            width: 75%;
        }
    }

    .start {
        color: ${({ disabled }) => (disabled ? "#a3a3a3" : "black")};
    }
`;
const ImageBox = styled.div`
    margin-top: 40px;
    margin-bottom: 100px;

    .source {
        ${applyTypography("secondary", 3)};
        color: #ababab;
        text-align: center;
    }
    @media (min-width: 768px) {
        margin-bottom: 200px;
    }
`;
const MapIcon = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    @media (min-width: 768px) {
        justify-content: center;
        gap: 35px;
    }

    .map-icon {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 33%;
        height: 40px;

        @media (min-width: 768px) {
            width: inherit;
        }
    }

    .icon {
        width: 23px;
    }

    .info {
        ${applyTypography("secondary", 3)};
    }
`;
