import logoFooter from "../../assets/images/main/logo-footer.svg";
import icoInsta from "../../assets/images/main/ico-insta.svg";
import icoMail from "../../assets/images/main/ico-mail.svg";
import icoPhone from "../../assets/images/main/ico-phone.svg";
import footLogo from "../../assets/images/seoulcon/logos.svg";
import { useEffect, useState } from "react";
import InfiniteRow from "./Pattern";
import styled from "styled-components";
import PaddingWraps from "../PaddingWraps";
import { applyTypography } from "../../styles/mixins";
import imgSba from "../../components/../assets/images/seoulcon/img-sba.png";
import bottom from "../../assets/images/main/bottom.svg";
import InfiniteRowMain from "./MainPattern";

const MainFooter = () => {
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
    }, []);
    return (
        <>
            <NormalBootom>
                <InfiniteRowMain />
                <InfiniteRowMain oddColor="#fed800" evenColor="#036eb8" />
                <Wrap>
                    <FooterWrap>
                        <Top>
                            <p className="desc">
                                {activeLanguage === "en"
                                    ? "Contents such as influencer lineup, overall timeline, and programs may be subject to change."
                                    : "인플루언서 라인업, 전체 타임라인, 프로그램 등의 내용은 변동이 있을 수 있습니다."}
                            </p>
                        </Top>
                        <Middle>
                            <img src={footLogo} alt="logo" className="logos" />
                            <div className="logobox">
                                <img src={imgSba} alt="logo" className="logo" />
                                <p className="info">
                                    {activeLanguage === "en"
                                        ? "Seoul Business Agency / 7F, S-PLEX CENTER, Synergyum, 31 Maebongsan-ro, Mapo-gu, Seoul, Republic of Korea\nTel: (+82)1577-7119 / Business Registration Number: 102-82-09623 / Personal Information Manager: Head of Creative industries"
                                        : "서울경제진흥원  /  03909 서울시 마포구 매봉산로 31 에스플렉스센터 시너지움 7층\n전화 : 1577-7119  /  사업자등록증 : 102-82-09623  /  개인정보관리책임자: 창조산업본부장"}
                                </p>
                            </div>
                            <img src={footLogo} alt="logo" className="logos-pc" />
                        </Middle>
                        <Bottom>
                            <div className="left">
                                <p>Copyrights SEOULCon. All rights reserved.</p>
                            </div>
                            <div className="right">
                                <a href="https://www.instagram.com/seoulcon_official" target="_blank">
                                    <img src={icoInsta} alt="instagram" />
                                </a>
                                <a href="tel:02-2677-2237">
                                    <img src={icoPhone} alt="phone" />
                                </a>
                                <a href="mailto:seoulcon@dnmd.com">
                                    <img src={icoMail} alt="mail" />
                                </a>
                            </div>
                        </Bottom>
                    </FooterWrap>
                </Wrap>
            </NormalBootom>
            <MainBottom>
                <div className="bottom-image">
                    <img src={bottom} alt="footer" className="btImage" />
                </div>
                <div className="bottom-text">
                    <div className="left">
                        <p>Copyrights SEOULCon. All rights reserved.</p>
                    </div>
                    <div className="right">
                        <a href="https://www.instagram.com/seoulcon_official" target="_blank">
                            <img src={icoInsta} alt="instagram" />
                        </a>
                        <a href="tel:02-2677-2237">
                            <img src={icoPhone} alt="phone" />
                        </a>
                        <a href="mailto:seoulcon@dnmd.com">
                            <img src={icoMail} alt="mail" />
                        </a>
                    </div>
                </div>
            </MainBottom>
        </>
    );
};

export default MainFooter;

const Wrap = styled.div`
    width: 100%;
    background-color: #000;
`;
const FooterWrap = styled.div`
    /* width: 96%; */
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`;

const Top = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 3%;
    /* .logo {
        width: 63px;
    } */
    .desc {
        margin-top: 24px;
        ${applyTypography("secondary", 2)};
        color: #ababab;
    }

    @media (min-width: 768px) {
        padding: 0px;
        padding-left: 32px;
    }
`;

const Middle = styled.div`
    margin-top: 32px;
    ${applyTypography("secondary", 2)};
    color: #ffffff;
    margin-bottom: 50px;
    padding: 0px 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 768px) {
        padding: 0px;
        padding-left: 32px;
        padding-right: 32px;
        .logobox {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
    @media (min-width: 1440px) {
        flex-direction: row;
    }
    .logo {
        width: 104px;
        height: 60px;
        margin-top: 16px;
    }
    .info {
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-word;
        line-height: 20px;
        margin-top: 16px;
    }
    .logos {
        width: 100%;
        max-width: 343px;
        @media (min-width: 768px) {
            max-width: 389px;
        }
        @media (min-width: 1440px) {
            display: none;
        }
    }
    .logos-pc {
        display: none;
        @media (min-width: 1440px) {
            display: block;
            max-width: 343px;
        }
    }
`;
const Bottom = styled.div`
    ${applyTypography("secondary", 2)}
    padding: 0px 3%;
    color: #f9f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    height: 50px;
    bottom: 0px;
    z-index: 300;
    background: black;
    width: 100%;

    .right {
        display: flex;
        gap: 12px;
        padding-right: 6%;
        @media (min-width: 768px) {
            padding: 0px;
            padding-right: 64px;
        }
    }
    @media (min-width: 768px) {
        padding: 0px;
        padding-left: 32px;
    }
    @media (min-width: 1200px) {
        display: none;
    }
`;

const NormalBootom = styled.div`
    @media (min-width: 1200px) {
        padding-left: 254px;
    }
`;
const MainBottom = styled.div`
    display: none;
    position: absolute;
    bottom: 0px;
    .bottom-image {
        position: fixed;
        bottom: 41px;
    }
    .bottom-text {
        display: flex;
        height: 50px;
        ${applyTypography("secondary", 2)}
        padding-left: 32px;
        color: #f9f8f8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        bottom: 0px;
        z-index: 300;
        background: black;
        width: 100%;
        .right {
            display: flex;
            gap: 12px;
            padding: 0px;
            padding-right: 64px;
        }
    }
    .btImage {
        width: 254px;
    }

    @media (min-width: 1200px) {
        display: block;
    }
`;
