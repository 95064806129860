import styled, { css } from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import star from "../../assets/images/seoulcon/img-star.svg";
import border from "../../assets/images/seoulcon/border.svg";
import tiktok from "../../assets/images/seoulcon/tiktok.svg";
import insta from "../../assets/images/seoulcon/instragram.svg";
import youtube from "../../assets/images/seoulcon/youtube.svg";
import weibo from "../../assets/images/seoulcon/weibo.svg";
import facebook from "../../assets/images/seoulcon/facebook.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";
import { formatFollowersCount } from "../../common/util-function";
import { applyTypography } from "../../styles/mixins";
import PaddingWraps from "../../components/PaddingWraps";
import Dividers from "../../components/Dividers";
import mega from "../../assets/images/seoulcon/mega.png";
import macro from "../../assets/images/seoulcon/macro.png";
import micro from "../../assets/images/seoulcon/micro.png";
import nano from "../../assets/images/seoulcon/nano.png";
import Wraps from "../../components/Wraps";
import Titles from "../../components/Title";
import guest from "../../assets/images/seoulcon/guest.svg";
import guestm from "../../assets/images/seoulcon/guest-m.svg";
import influback from "../../assets/images/seoulcon/img-slide.svg";
import empty from "../../assets/images/seoulcon/empty.png";

interface InfluencerCardProps {
    value: any;
    language: string;
    delay: number;
    isPlaceholder?: boolean;
    border?: boolean;
    mainpc?: boolean;
}

// 중복되는 Card 컴포넌트 생성
const InfluencerCard: React.FC<InfluencerCardProps> = ({
    value,
    language,
    delay,
    isPlaceholder = false,
    border = false,
    mainpc = false,
}) => {
    const snsIcon =
        value.sns === "tiktok"
            ? tiktok
            : value.sns === "instagram"
            ? insta
            : value.sns === "youtube"
            ? youtube
            : value.sns === "facebook"
            ? facebook
            : weibo;
    const levelType = (type: string) => {
        switch (type) {
            case "mega":
                return (
                    <div className="level-icon level-mega">
                        <img src={mega} alt="mega" />
                        <p>메가</p>
                    </div>
                );
            case "macro":
                return (
                    <div className="level-icon level-macro">
                        <img src={macro} alt="macro" />

                        <p>매크로</p>
                    </div>
                );
            case "micro":
                return (
                    <div className="level-icon level-micro">
                        <img src={micro} alt="micro" />
                        <p>마이크로</p>
                    </div>
                );
            case "nano":
                return (
                    <div className="level-icon level-nano">
                        <img src={nano} alt="nano" />
                        <p>나노</p>
                    </div>
                );
            default:
                return "일반";
        }
    };
    return (
        <CardWrapper mainpc={mainpc}>
            <a href={value.link} target="_blank" rel={value.nameKo} className={` ${border ? "border" : ""}`}>
                <div className="width">
                    {/* <img src={`${APIURL}/${value.imagePath}`} alt={value.nameKo} className="influencer" /> */}

                    <img
                        src={
                            value.imagePath && value.imagePath.includes("uploads/")
                                ? `${APIURL}/${value.imagePath}`
                                : value.imagePath
                                ? value.imagePath
                                : empty
                        }
                        alt={value.nameKo}
                        className="influencer"
                    />
                </div>
                {/* {levelType(value.level)} */}
            </a>
            {value.nameKo && (
                <>
                    <SnsWrap>
                        <div className="wrap sns">
                            <img src={snsIcon} alt={value.sns} />
                        </div>
                        <div className="wrap names">
                            <img
                                src={
                                    value.flag
                                        ? value.flag
                                        : "https://opendata.mofa.go.kr:8444/fileDownload/images/country_images/flags/241/20220224_233513043.gif"
                                }
                                alt="국기"
                            />
                        </div>
                    </SnsWrap>
                    <p className="username">{language === "en" ? value.nameEn : value.nameKo}</p>
                    <b className="follow">{formatFollowersCount(value.follow ? value.follow : value.follower)}</b>
                </>
            )}
        </CardWrapper>
    );
};

const Lineup: React.FC = () => {
    const [promotion, setPromotion] = useState<any[]>([]);
    const [list, setList] = useState<any[]>([]);
    const [influKo, setInfluKo] = useState<any | null>(null);
    const [influEn, setInfluEn] = useState<any | null>(null);
    const [activeLanguage, setActiveLanguage] = useState<string>("ko");
    const [selectedLevel, setSelectedLevel] = useState<string>("all");
    const [sortOrder, setSortOrder] = useState<string>("DESC");
    const [itemsPerPage] = useState(100); // Items per page set to 100
    const [currentPage, setCurrentPage] = useState(1);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLevel(event.target.value);
    };

    const filteredList = list.filter((value) => {
        if (selectedLevel === "all") return true;
        return value.level === selectedLevel;
    });

    const currentItems = filteredList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPages = Math.ceil(filteredList.length / itemsPerPage);

    const goToPreviousPage = () => {
        setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
    };

    const goToNextPage = () => {
        setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
    };

    const handlePageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPost = async () => {
            try {
                const response = await axios.get(`${APIURL}/manage/lineup`);
                const result = response.data.data;
                setInfluKo(result.influKo);
                setInfluEn(result.influEn);

                const promotion = await axios.get(`${APIURL}/influencer/v2/promotion`);
                setPromotion(promotion.data.data);

                const allList = await axios.get(`${APIURL}/influencer/v2/allList?sortOrder=${sortOrder}`);
                setList(allList.data.data);
            } catch (error) {
                console.error("Can't fetch the post:", error);
            }
        };

        fetchPost();
    }, [sortOrder]);

    const renderPagination = () => {
        const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
        return (
            <Pagination>
                <button className="btn" onClick={goToPreviousPage} disabled={currentPage === 1}>
                    {"◀︎"}
                </button>
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        onClick={() => handlePageClick(number)}
                        className={currentPage === number ? "active" : ""}
                    >
                        {number}
                    </button>
                ))}
                <button className="btn" onClick={goToNextPage} disabled={currentPage === totalPages}>
                    {"▶"}
                </button>
            </Pagination>
        );
    };
    return (
        <>
            <Helmet>
                <title>{activeLanguage === "en" ? `SEOULCon-Influencer Lineup` : `서울콘-인플루언서 라인업`}</title>
            </Helmet>
            <Navibar />
            <Wrap>
                <PaddingWrap>
                    <Title>{activeLanguage === "en" ? influEn?.title : influKo?.title}</Title>
                    <Ment>{activeLanguage === "en" ? influEn?.content : influKo?.content}</Ment>
                    <Divider />
                    <Ambassadors>{activeLanguage === "en" ? "Special Guest" : "스페셜 게스트"}</Ambassadors>
                    <ImgBoxGuest>
                        <img className="guest gpc" src={guest} alt="guest" />
                        <img className="guest gm" src={guestm} alt="guest" />
                    </ImgBoxGuest>
                    <Divider />
                    <Ambassadors>{activeLanguage === "en" ? influEn?.ambass : influKo?.ambass}</Ambassadors>
                    <ImgBox>
                        {promotion.map((value, index) => (
                            <InfluencerCard
                                key={value.id || index}
                                value={value}
                                language={activeLanguage}
                                delay={index * 0.1}
                                border={true}
                                mainpc={true}
                            />
                        ))}
                    </ImgBox>
                    {/* <Divider2 />
                    <InfluSelect>
                        <Influencer>{activeLanguage === "en" ? influEn?.influ : influKo?.influ}</Influencer>
                        <Container>
                            <SelectBox>
                                <select
                                    value={selectedLevel}
                                    onChange={handleSelectChange}
                                    className="select-container"
                                >
                                    <option value="all">{activeLanguage === "en" ? "All Tiers" : "등급전체"}</option>
                                    <option value="mega">
                                        {activeLanguage === "en" ? "Mega (1M+)" : "메가(100만명 이상)"}
                                    </option>
                                    <option value="macro">
                                        {activeLanguage === "en" ? "Macro (100K ~ 1M)" : "매크로(10만 ~ 100만명)"}
                                    </option>
                                    <option value="micro">
                                        {activeLanguage === "en" ? "Micro (10K ~ 100K) " : "마이크로(1만 ~ 10만명)"}
                                    </option>
                                    <option value="nano">
                                        {activeLanguage === "en" ? "Nano (1K - 10K)" : "나노(1,000 ~ 1만명)"}
                                    </option>
                                </select>
                            </SelectBox>
                            <SelectBox>
                                <select
                                    value={sortOrder}
                                    onChange={(e) => setSortOrder(e.target.value)}
                                    className="select-container"
                                >
                                    <option value="DESC">{activeLanguage === "en" ? "Descending" : "내림차순"}</option>
                                    <option value="ASC">{activeLanguage === "en" ? "Ascending" : "오름차순"}</option>
                                </select>
                            </SelectBox>
                        </Container>
                    </InfluSelect> */}
                    {/* <ImgBox>
                        {currentItems.map((value, index) => (
                            <InfluencerCard
                                key={Math.random()}
                                value={value}
                                language={activeLanguage}
                                delay={index * 0.1}
                            />
                        ))}
                    </ImgBox>
                    {renderPagination()} */}
                    {/* <ImgBoxBack>
                        <img className="guest" src={influback} alt="guest" />
                    </ImgBoxBack> */}
                    <Divid />
                </PaddingWrap>
            </Wrap>
            <Footer />
        </>
    );
};

export default Lineup;

const Wrap = styled(Wraps)``;

const PaddingWrap = styled(PaddingWraps)`
    @media (min-width: 1440px) {
        max-width: 1200px;
    }
`;

const Title = styled(Titles)``;

const Ment = styled.p`
    ${applyTypography("secondary", 1)};
    margin-top: 8px;
`;

const Divider = styled(Dividers)``;

const Divider2 = styled(Divider)`
    margin-bottom: 24px;
    @media (min-width: 1440px) {
        margin-top: 60px;
    }
`;

const Ambassadors = styled.h1`
    ${applyTypography("primary", 3)};
    padding-bottom: 20px;
    @media (min-width: 768px) {
        padding-bottom: 24px;
    }
    @media (min-width: 1440px) {
        padding-bottom: 40px;
    }
`;

const Influencer = styled(Ambassadors)``;

const ImgBox = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;
const ImgBoxGuest = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 32px;

    @media (min-width: 768px) {
        padding-bottom: 72px;
    }
    .guest {
        width: 100%;
    }
    .gm {
        max-width: 704px;
        @media (min-width: 1440px) {
            display: none;
        }
    }
    .gpc {
        display: none;
        @media (min-width: 1440px) {
            display: block;
        }
    }
`;
const ImgBoxBack = styled(ImgBoxGuest)`
    margin-top: 40px;
    @media (min-width: 768px) {
        margin-top: 100px;
    }
`;

const CardWrapper = styled.div<{ mainpc: boolean }>`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 49%;
    margin-bottom: 40px;
    overflow: hidden;
    a {
        overflow: hidden;
    }

    &:nth-child(odd) {
        margin-right: 2%;

        @media (min-width: 768px) {
            margin-right: 0;
        }
        @media (min-width: 1440px) {
            margin-right: 0;
        }
    }
    &:not(:nth-child(3n)) {
        @media (min-width: 768px) {
            margin-right: 2%;
        }
        @media (min-width: 1440px) {
            margin-right: 0;
        }
    }
    &:not(:nth-child(5n)) {
        @media (min-width: 1440px) {
            margin-right: 2% !important;
        }
    }
    @media (min-width: 768px) {
        width: 32%;

        /* &:nth-child(odd) {
            margin-right: 0;
        } */
        /* &:not(:nth-child(3n)) {
            margin-right: 2%;
        } */
    }

    @media (min-width: 1440px) {
        width: ${({ mainpc }) => (mainpc ? "23%" : "18.4%")};
        /* &:not(:nth-child(3n)) {
            margin-right: 0;
        } */
        /* &:nth-child(odd) {
            margin-right: 0;
        } */
        /* &:not(:nth-child(5n)) {
            margin-right: 2% !important;
        } */
    }

    @keyframes grow {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.1);
        }
    }
    .border {
        border: solid 4px #fed800;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        /* height: 45vw; */
        object-fit: cover;
    }
    .width {
        position: relative;
        width: 45vw;
        height: 44vw;
        overflow: hidden;

        @media (min-width: 768px) {
            width: 31vw;
            height: 30vw;
        }
        @media (min-width: 1440px) {
            height: ${({ mainpc }) => (mainpc ? "268px;" : "221px;")};
            width: ${({ mainpc }) => (mainpc ? "45vw;" : "221px;")};
        }
        img {
            width: 100%;
            height: 100%;
            /* aspect-ratio: 16 / 9; */
            position: relative;
            object-fit: cover;
            /* transform: scale(1.8); */
            transform-origin: top;
            transition: transform 0.3s ease;

            /* @media (min-width: 1440px) {
                transform: ${({ mainpc }) => (mainpc ? "scale(1.8)" : "scale(1)")};
            } */
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 105, 52, 0.3);
            opacity: 0;
            transition: opacity 0.3s ease;
            z-index: 5;
        }

        &:hover::after {
            opacity: 1;
        }
        &:hover {
            animation: grow 0.2s ease-in-out forwards;
        }
    }
    .level-icon {
        display: flex;
        align-items: center;
        position: absolute;
        padding: 4px 12px;
        top: 0px;
        right: 0px;
        z-index: 10;

        ${applyTypography("secondary", 2)};

        p {
            margin-left: 10px;
        }
    }
    .level-mega {
        background-color: #fed800;
    }
    .level-macro {
        background-color: #c30d23;
        color: #f9f8f8;
    }
    .level-micro {
        background-color: #036eb8;
        color: #f9f8f8;
    }
    .level-nano {
        background-color: #006934;
        color: #f9f8f8;
    }
    .username {
        margin-top: 16px;
        ${applyTypography("secondary", 1)};
    }
    .follow {
        margin-top: 16px;
        ${applyTypography("primary", 3)};
    }
`;

const SnsWrap = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 12px;

    .wrap {
        background-color: white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
    }

    .sns img {
        width: 25px;
    }
    .names img {
        width: 25px;
    }
`;
const InfluSelect = styled.div`
    @media (min-width: 1440px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2%;
    justify-content: space-between;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 40px;
    @media (min-width: 1440px) {
        width: 38%;
    }
`;
const SelectBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    position: relative;
    width: 50%;

    &::after {
        content: "▼";
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: 14px;
        color: black;
    }

    select {
        box-sizing: border-box;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: white;
        border: 4px solid black;
        border-radius: 0;
        padding: 10px 40px 10px 10px;
        width: 100%;
        cursor: pointer;
        ${applyTypography("secondary", 3)};

        &:focus {
            outline: none;
            border-color: #000;
        }
    }

    @media (min-width: 1440px) {
        margin-top: 0px;
    }
`;
const Divid = styled.div`
    margin-bottom: 100px;
    @media (min-width: 768px) {
        margin-bottom: 200px;
    }
`;
const Pagination = styled.div`
    margin-top: 28px;
    display: flex;
    justify-content: center;
    gap: 10px;
    .active {
        color: black;
    }

    button {
        color: #ababab;
        background: none;
        border: none;
        cursor: pointer;

        &:disabled {
            border: solid 4px #ababab;
            color: #ababab;
            background: none;

            &:hover {
                border: solid 4px #ababab;
                background-color: #ffffff;
                color: #ababab;
                cursor: not-allowed;
            }
        }
    }
    .btn {
        border: solid 4px black;
        color: black;
        font-weight: bold;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            transition: background-color 0.3s ease;
            background-color: #000000;
            color: #ffffff;
        }
    }
`;
