import styled from "styled-components";
import charFinger from "../../assets/images/program/char-finger.svg";
import Navibar from "../../components/navigation/Navibar";
import Footer from "../../components/footer/Footer";
import Triangle from "../../assets/images/main/img-triangle.svg";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import dateEn from "../../assets/images/program/img-date-en.svg";
import dateKo from "../../assets/images/program/img-date-ko.svg";
import locationEn from "../../assets/images/program/img-location-en.svg";
import locationKo from "../../assets/images/program/img-location-ko.svg";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { compareYear, formatDateDay, formatDateToUS, formatEndTime } from "../../common/util-function";
import { useInView } from "react-intersection-observer";
import { MainContent } from "../main/Main";
import { Helmet } from "react-helmet";
import { applyTypography } from "../../styles/mixins";
import PaddingWraps from "../../components/PaddingWraps";
import Dividers from "../../components/Dividers";
import Wraps from "../../components/Wraps";
import Titles from "../../components/Title";

interface Post {
    id: string;
    titleEn: string;
    titleKo: string;
    date: string;
    endDate?: string;
    startTime?: string;
    endTime?: string;
    imagePath: string;
    placeEn: string;
    placeKo: string;
    dateTimes: [
        {
            date: string;
            endTime: string;
            startTime: string;
        }
    ];
}

interface CommonFestivalProps {
    apiEndpoint: string;
    imagePathKey: "imagePath1" | "imagePath2" | "imagePath3" | "imagePath4" | "imagePath5";
}

const CommonFestival: React.FC<CommonFestivalProps> = ({ apiEndpoint, imagePathKey }) => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [activeLanguage, setActiveLanguage] = useState<string>("ko");
    const [mains, setMains] = useState<MainContent | undefined>();

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${APIURL}/program/${apiEndpoint}`);
                const mainsResponse = await axios.get(`${APIURL}/manage/main`);

                setPosts(response.data.data);
                setMains(mainsResponse.data.data);
            } catch (error) {
                console.error("Error fetching the posts:", error);
            }
        };
        fetchPosts();
    }, [apiEndpoint]);

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
        initialInView: false,
    });

    //높이확인
    const containerRef = useRef<HTMLDivElement>(null);
    const [isLargeHeight, setIsLargeHeight] = useState(false);
    useEffect(() => {
        // 요소의 높이를 확인
        const checkHeight = () => {
            if (containerRef.current) {
                const height = containerRef.current.clientHeight;
                setIsLargeHeight(height > 700); // 높이가 700px 이상일 경우 true
            }
        };

        checkHeight(); // 처음 로딩 시 높이 확인
        window.addEventListener("resize", checkHeight); // 창 크기 조정 시에도 확인

        return () => window.removeEventListener("resize", checkHeight); // 리스너 해제
    }, []);

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-${mains?.[imagePathKey]?.en}`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-${mains?.[imagePathKey]?.ko}`}</title>
                </Helmet>
            )}
            <Navibar />
            <Wrap>
                <PaddingWrap>
                    <img src={charFinger} alt="도형" className="Triangle" />
                    <Title>{activeLanguage === "en" ? mains?.[imagePathKey]?.en : mains?.[imagePathKey]?.ko}</Title>
                    <Divider />
                    <Contents>
                        {posts.map((post, index) => (
                            <div
                                key={post.id}
                                className={`box visible animate__animated animate__fadeInUp`}
                                ref={ref}
                                style={{ animationDelay: `${index * 0.1}s` }}
                            >
                                <Link to={`${post.id}`}>
                                    <div className="imgBox">
                                        <img
                                            src={`${APIURL}/${post.imagePath}`}
                                            alt={post.titleKo}
                                            className="conImg"
                                        />
                                    </div>
                                </Link>
                                <Center>
                                    <div className="title">{activeLanguage === "en" ? post.titleEn : post.titleKo}</div>
                                    <div className="date">
                                        <div className="first">{activeLanguage === "en" ? "Date" : "일정"}</div>
                                        <div className="last">
                                            {post.dateTimes && post.dateTimes.length > 0 ? (
                                                post.dateTimes.length === 1 ? (
                                                    <>
                                                        {activeLanguage === "en" ? (
                                                            <>{formatDateToUS(post.dateTimes[0].date)}</>
                                                        ) : (
                                                            <>{formatDateDay(post.dateTimes[0].date)}</>
                                                        )}{" "}
                                                        {post.dateTimes[0].startTime &&
                                                            `${formatEndTime(post.dateTimes[0].startTime)} ~ `}
                                                        {post.dateTimes[0].endTime &&
                                                            formatEndTime(post.dateTimes[0].endTime)}
                                                    </>
                                                ) : (
                                                    <>
                                                        {activeLanguage === "en" ? (
                                                            <>
                                                                {formatDateToUS(post.dateTimes[0].date)}
                                                                {post.dateTimes[post.dateTimes.length - 1].date
                                                                    ? ` ~ ${compareYear(
                                                                          formatDateToUS(post.dateTimes[0].date),
                                                                          formatDateToUS(
                                                                              post.dateTimes[post.dateTimes.length - 1]
                                                                                  .date
                                                                          )
                                                                      )}`
                                                                    : null}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {formatDateDay(post.dateTimes[0].date)}
                                                                {post.dateTimes[post.dateTimes.length - 1].date
                                                                    ? ` ~ ${compareYear(
                                                                          formatDateDay(post.dateTimes[0].date),
                                                                          formatDateDay(
                                                                              post.dateTimes[post.dateTimes.length - 1]
                                                                                  .date
                                                                          )
                                                                      )}`
                                                                    : null}
                                                            </>
                                                        )}{" "}
                                                        {post.dateTimes[0].startTime &&
                                                            `${formatEndTime(post.dateTimes[0].startTime)} ~ `}
                                                        {post.dateTimes[post.dateTimes.length - 1].endTime &&
                                                            formatEndTime(
                                                                post.dateTimes[post.dateTimes.length - 1].endTime
                                                            )}
                                                    </>
                                                )
                                            ) : (
                                                <>
                                                    {activeLanguage === "en" ? (
                                                        <>
                                                            {formatDateToUS(post.date)}
                                                            {post.endDate
                                                                ? ` ~ ${compareYear(
                                                                      formatDateToUS(post.date),
                                                                      formatDateToUS(post.endDate)
                                                                  )}`
                                                                : null}{" "}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {formatDateDay(post.date)}
                                                            {post.endDate
                                                                ? ` ~ ${compareYear(
                                                                      formatDateDay(post.date),
                                                                      formatDateDay(post.endDate)
                                                                  )}`
                                                                : null}{" "}
                                                        </>
                                                    )}
                                                    {post.startTime && `${formatEndTime(post.startTime)} ~ `}
                                                    {post.endTime && formatEndTime(post.endTime)}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="place">
                                        <div className="first">{activeLanguage === "en" ? "Place" : "장소"}</div>
                                        <div className="last">
                                            {activeLanguage === "en" ? post.placeEn : post.placeKo}
                                        </div>
                                    </div>
                                </Center>
                            </div>
                        ))}
                    </Contents>
                    <Divid />
                </PaddingWrap>
            </Wrap>
            <Footer />
        </>
    );
};

export default CommonFestival;

const Wrap = styled(Wraps)<{ isLargeHeight: boolean }>`
    @media (min-width: 1440px) {
        margin-bottom: ${({ isLargeHeight }) => (isLargeHeight ? "200px" : "0px")};
    }
`;

const PaddingWrap = styled(PaddingWraps)`
    .Triangle {
        display: none;
    }

    @media (min-width: 768px) {
        .Triangle {
            display: block;
            position: absolute;
            z-index: 100;
            top: 35px;
            right: 0px;
        }
    }

    @media (min-width: 1440px) {
        .Triangle {
            right: -90px;
        }
    }
`;

const Title = styled(Titles)``;

const Divider = styled(Dividers)``;

const Contents = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    a {
        overflow: hidden;
        display: flex;
        width: 100%;
        border: solid 4px black;
        box-sizing: border-box;
    }
    .box {
        width: 100%;

        @media (min-width: 1440px) {
            width: 49.5%;
        }
    }
    .visible {
        visibility: visible;
    }

    .animate__animated {
        animation-delay: 0.1s;
    }
    @keyframes grow {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.1);
        }
    }
    .imgBox {
        position: relative; /* 부모 요소에 relative 설정 */
        width: 100%;
        height: 50vw;
        box-sizing: border-box;
        object-fit: cover;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 105, 52, 0.5); /* 초록색 투명 배경 (50% 투명도) */
            opacity: 0; /* 기본 상태에서는 투명하게 */
            transition: opacity 0.2s ease; /* 오버레이의 부드러운 트랜지션 */
            z-index: 100;
        }

        &:hover::after {
            opacity: 1; /* 마우스 오버 시 오버레이가 보이도록 */
        }
        &:hover {
            animation: grow 0.2s ease-in-out forwards;
        }
        @media (min-width: 768px) {
            height: 45vw;
            max-height: 400px;
        }
        @media (min-width: 1440px) {
            height: 25vw;
        }
    }
    .conImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 16 / 9;
        position: relative;
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(15%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;
const Center = styled.div`
    .title {
        ${applyTypography("primary", 3)};
        margin-top: 20px;
        margin-bottom: 16px;
    }

    @media (min-width: 768px) {
        margin-top: 28px;
    }
    .date,
    .place {
        display: flex;
        align-items: center;
        gap: 2%;
        ${applyTypography("secondary", 1)};
        .first {
            background-color: #fed800;
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
        }
        .last {
            width: 70%;
        }
    }
    .date {
        margin-bottom: 8px;
    }
    .place {
        margin-bottom: 44px;

        @media (min-width: 768px) {
            margin-bottom: 60px;
        }
    }
`;
const Divid = styled.div`
    margin-bottom: 100px;
    @media (min-width: 768px) {
        margin-bottom: 200px;
    }
`;
