import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import styled from "styled-components";
import charStay from "../../assets/images/program/char-stay.svg";
import imgSba from "../../components/../assets/images/seoulcon/img-sba.svg";
import san from "../../components/../assets/images/seoulcon/san.svg";
import san2 from "../../components/../assets/images/seoulcon/san2.svg";
import cloud from "../../components/../assets/images/seoulcon/cloud.svg";
import cloud2 from "../../components/../assets/images/seoulcon/cloud2.svg";
import circle from "../../components/../assets/images/seoulcon/circle.svg";
import rectangle from "../../components/../assets/images/seoulcon/rectangle.svg";
import red from "../../components/../assets/images/seoulcon/red.svg";
import green from "../../components/../assets/images/seoulcon/green.svg";
import icoBack from "../../components/../assets/images/seoulcon/icon-back.svg";
import intro1 from "../../assets/images/seoulcon/img-intro1.svg";
import intro2 from "../../assets/images/seoulcon/img-intro2.svg";
import intro3 from "../../assets/images/seoulcon/img-intro3.svg";
import intro4 from "../../assets/images/seoulcon/img-intro4.svg";
import intro_mb_1 from "../../assets/images/seoulcon/img-intro-mb1.svg";
import intro_mb_2 from "../../assets/images/seoulcon/img-intro-mb2.svg";
import intro_mb_3 from "../../assets/images/seoulcon/img-intro-mb3.svg";
import intro_mb_4 from "../../assets/images/seoulcon/img-intro-mb4.svg";
import intro from "../../assets/images/seoulcon/img-intro-main.json";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import Lottie from "lottie-web";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import { applyTypography } from "../../styles/mixins";

const SeoulconAbout = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [titleKo, setTitleKo] = useState("");
    const [titleEn, setTitleEn] = useState("");
    const [contentKo, setContentKo] = useState("");
    const [contentEn, setContentEn] = useState("");
    const [numbers, setNumbers] = useState({
        influencer: "",
        audience: "",
        viewer: "",
        influencerKo: "",
        audienceKo: "",
        viewerKo: "",
        influencerEn: "",
        audienceEn: "",
        viewerEn: "",
    });
    const [type1Ko, setType1Ko] = useState({ title: "", content: "" });
    const [type2Ko, setType2Ko] = useState({ title: "", content: "" });
    const [type3Ko, setType3Ko] = useState({ title: "", content: "" });
    const [type4Ko, setType4Ko] = useState({ title: "", content: "" });
    const [type1En, setType1En] = useState({ title: "", content: "" });
    const [type2En, setType2En] = useState({ title: "", content: "" });
    const [type3En, setType3En] = useState({ title: "", content: "" });
    const [type4En, setType4En] = useState({ title: "", content: "" });
    const [infoKo, setInfoKo] = useState({ title: "", content: "" });
    const [infoEn, setInfoEn] = useState({ title: "", content: "" });
    //숫자 애니메이션
    const [influencerCount, setInfluencerCount] = useState(0);
    const [audienceCount, setAudienceCount] = useState(0);
    const [viewerCount, setViewerCount] = useState(0);
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");

    useEffect(() => {
        const fetchPost = async () => {
            setActiveLanguage(sessionStorage.getItem("lang") || "ko");

            try {
                const response = await axios.get(`${APIURL}/manage/about`);
                //console.log(response.data.data);
                const result = response.data.data;
                const formattedContentKo = result.contentKo.replace(/\n/g, "<br />");
                const formattedContentEn = result.contentEn.replace(/\n/g, "<br />");
                setTitleKo(result.titleKo);
                setTitleEn(result.titleEn);
                setContentKo(result.contentKo);
                setContentEn(result.contentEn);
                setNumbers({
                    influencer: result.numbers.influencer,
                    audience: result.numbers.audience,
                    viewer: result.numbers.viewer,
                    influencerKo: result.numbers.influencerKo,
                    audienceKo: result.numbers.audienceKo,
                    viewerKo: result.numbers.viewerKo,
                    influencerEn: result.numbers.influencerEn,
                    audienceEn: result.numbers.audienceEn,
                    viewerEn: result.numbers.viewerEn,
                });
                setType1Ko({
                    title: result.type1Ko.title,
                    content: result.type1Ko.content,
                });
                setType2Ko({
                    title: result.type2Ko.title,
                    content: result.type2Ko.content,
                });
                setType3Ko({
                    title: result.type3Ko.title,
                    content: result.type3Ko.content,
                });
                setType4Ko({
                    title: result.type4Ko.title,
                    content: result.type4Ko.content,
                });
                setType1En({
                    title: result.type1En.title,
                    content: result.type1En.content,
                });
                setType2En({
                    title: result.type2En.title,
                    content: result.type2En.content,
                });
                setType3En({
                    title: result.type3En.title,
                    content: result.type3En.content,
                });
                setType4En({
                    title: result.type4En.title,
                    content: result.type4En.content,
                });
                setInfoKo({
                    title: result.infoKo.title,
                    content: result.infoKo.content,
                });
                setInfoEn({
                    title: result.infoEn.title,
                    content: result.infoEn.content,
                });
            } catch (error) {
                console.error("Can't fetch the post:", error);
            }
        };

        fetchPost();
    }, [activeLanguage]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    //lottie
    // const animationContainer = useRef(null);

    // useEffect(() => {
    //     if (animationContainer.current) {
    //         const anim = Lottie.loadAnimation({
    //             container: animationContainer.current,
    //             renderer: "svg",
    //             loop: true,
    //             autoplay: true,
    //             animationData: intro,
    //         });

    //         return () => anim.destroy();
    //     }
    // }, [intro]);

    // 숫자 애니메이션, 숫자를 증가시키는 함수
    const animateValue = (start: number, end: number, duration: number, setter: (value: number) => void) => {
        let current = start;
        const range = end - start;
        let increment = Math.floor(range / ((duration / 1000) * 60));
        let stepTime = 1000 / 60;

        const updateValue = () => {
            if (current < end) {
                setter(current);
                current += increment;
                setTimeout(updateValue, stepTime);
            } else {
                setter(end);
            }
        };

        setTimeout(updateValue, stepTime);
    };

    // useEffect(() => {
    //     animateValue(0, Number(numbers.influencer), 2000, setInfluencerCount);
    //     animateValue(0, Number(numbers.audience), 2300, setAudienceCount);
    //     animateValue(0, Number(numbers.viewer), 2600, setViewerCount);
    // }, [activeLanguage, numbers]);
    useEffect(() => {
        // 화면이 PC인지 모바일인지 확인
        const isMobile = window.innerWidth <= 820;

        // PC 화면에서는 바로 실행
        if (!isMobile) {
            animateValue(0, Number(numbers.influencer), 1000, setInfluencerCount);
            animateValue(0, Number(numbers.audience), 1300, setAudienceCount);
            animateValue(0, Number(numbers.viewer), 1600, setViewerCount);
            return;
        }

        let executed = false; // 함수가 실행됐는지 확인하기 위한 변수

        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            // 아직 실행되지 않았고, 스크롤 위치가 500px을 넘었을 때 실행
            if (!executed && scrollPosition > 230) {
                animateValue(0, Number(numbers.influencer), 1000, setInfluencerCount);
                animateValue(0, Number(numbers.audience), 1300, setAudienceCount);
                animateValue(0, Number(numbers.viewer), 1600, setViewerCount);
                executed = true; // 함수 실행 후 변수 업데이트
            }
        };

        // 모바일 화면에서 스크롤 이벤트 리스너 추가
        if (isMobile) {
            window.addEventListener("scroll", handleScroll);
        }

        // 이벤트 리스너 제거
        return () => {
            if (isMobile) {
                window.removeEventListener("scroll", handleScroll);
            }
        };
    }, [numbers.influencer, numbers.audience, numbers.viewer]);

    // 애니메이션
    const { ref: appRef, inView: appView } = useInView({
        threshold: 0.1,
    });

    const { ref: listRef, inView: listView } = useInView({
        threshold: 0.9,
        triggerOnce: true,
    });

    const { ref: listRef2, inView: listView2 } = useInView({
        threshold: 0.9,
        triggerOnce: true,
    });

    const { ref: listRef3, inView: listView3 } = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });

    const { ref: listRef4, inView: listView4 } = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-About`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-About`}</title>
                </Helmet>
            )}
            <Navibar />
            <Wrap>
                <PaddingWrap>
                    <AboutWrap>
                        <div className="left">
                            <img src={charStay} alt="도형" className="triangle" />
                            <img src={icoBack} alt="도형" className="icoBack" />
                        </div>

                        <div className="right">
                            <h1>{activeLanguage === "en" ? titleEn : titleKo}</h1>
                            <p className="text">{activeLanguage === "en" ? contentEn : contentKo}</p>
                            <div className="sectonBox">
                                <section className="first visible animate__animated animate__fadeInUp">
                                    <p className="title">
                                        {activeLanguage === "en" ? numbers.influencerEn : numbers.influencerKo}
                                    </p>
                                    <p className="num">{influencerCount.toLocaleString()}+</p>
                                </section>
                                <section className="second visible animate__animated animate__fadeInUp">
                                    <p className="title">
                                        {activeLanguage === "en" ? numbers.audienceEn : numbers.audienceKo}
                                    </p>
                                    <p className="num">{audienceCount.toLocaleString()}+</p>
                                </section>
                                <section className="third visible animate__animated animate__fadeInUp">
                                    <p className="title">
                                        {activeLanguage === "en" ? numbers.viewerEn : numbers.viewerKo}
                                    </p>
                                    <p className="num">{viewerCount.toLocaleString()}+</p>
                                </section>
                            </div>
                        </div>
                    </AboutWrap>
                    <AdWrap></AdWrap>

                    <ListWrap>
                        <div
                            className={`listbox first ${listView ? "visible animate__animated animate__fadeInUp" : ""}`}
                            ref={listRef}
                        >
                            <p className="left">{activeLanguage === "en" ? type1En.title : type1Ko.title}</p>

                            <div className="right">
                                <p>{activeLanguage === "en" ? type1En.content : type1Ko.content}</p>
                            </div>
                            <img className="leftCircle listImg" src={circle} alt="circle" />
                            <img className="rightCircle listImg" src={circle} alt="circle" />
                        </div>

                        <div
                            className={`listbox second ${
                                listView2 ? "visible animate__animated animate__fadeInUp" : ""
                            }`}
                            ref={listRef2}
                        >
                            <p className="left">{activeLanguage === "en" ? type2En.title : type2Ko.title}</p>

                            <div className="right">
                                <p>{activeLanguage === "en" ? type2En.content : type2Ko.content}</p>
                            </div>
                            <img className="leftRed listImg" src={red} alt="red" />
                            <img className="rightRed listImg" src={red} alt="red" />
                        </div>

                        <div
                            className={`listbox third ${
                                listView3 ? "visible animate__animated animate__fadeInUp" : ""
                            }`}
                            ref={listRef3}
                        >
                            <p className="left">{activeLanguage === "en" ? type3En.title : type3Ko.title}</p>

                            <div className="right">
                                <p>{activeLanguage === "en" ? type3En.content : type3Ko.content}</p>
                            </div>
                            <img className="leftGreen listImg" src={green} alt="green" />
                            <img className="rightGreen listImg" src={green} alt="green" />
                        </div>

                        <div
                            className={`listbox fourth ${
                                listView4 ? "visible animate__animated animate__fadeInUp" : ""
                            }`}
                            ref={listRef4}
                        >
                            <p className="left">{activeLanguage === "en" ? type4En.title : type4Ko.title}</p>
                            <div className="right">
                                <p>{activeLanguage === "en" ? type4En.content : type4Ko.content}</p>
                            </div>
                            <img className="leftRect listImg" src={rectangle} alt="rect" />
                            <img className="rightRect listImg" src={rectangle} alt="rect" />
                        </div>
                    </ListWrap>

                    <SbaWrap>
                        <div className="wrapInner">
                            <img className="logo" src={imgSba} alt="서울경제진흥원" />
                            <b className="title">{activeLanguage === "en" ? infoEn.title : infoKo.title}</b>
                            <p className="text">{activeLanguage === "en" ? infoEn.content : infoKo.content}</p>
                            <img className="leftTree tree" src={san} alt="tree" />
                            <img className="rightTree tree" src={san2} alt="tree" />
                            <img className="leftCloud cloud" src={cloud} alt="cloud" />
                            <img className="leftCloud cloud tempcloud" src={cloud2} alt="cloud" />
                            <img className="rightCloud cloud" src={cloud2} alt="cloud" />
                        </div>
                    </SbaWrap>
                </PaddingWrap>
            </Wrap>
            <Footer />
        </>
    );
};

export default SeoulconAbout;

const Wrap = styled.div`
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
`;

const PaddingWrap = styled(Wraps)`
    flex-direction: column;
    align-items: center;
`;

const AboutWrap = styled(PaddingWraps)`
    .icoBack {
        display: none;
    }
    @media (min-width: 1440px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        max-width: 1200px;
        .icoBack {
            display: block;
            margin-top: 52px;
        }
    }
    .left {
        margin-top: 46px;
        display: flex;
        justify-content: center;

        .triangle {
            width: 153px;
        }

        @media (min-width: 768px) {
            margin-top: 22px;
            .triangle {
                width: 260px;
            }
        }
        @media (min-width: 1440px) {
            width: 50%;
            margin-top: 62px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .right {
        margin-top: 44px;
        h1 {
            ${applyTypography("primary", 1)};
        }

        .text {
            margin-top: 20px;
            line-height: 25px;
            ${applyTypography("secondary", 0)};
        }
        .sectonBox {
            margin-top: 40px;
        }
        .second,
        .third {
            margin-top: 28px;
        }
        .title {
            ${applyTypography("secondary", 2)};
        }
        .num {
            margin-top: 14px;
            ${applyTypography("primary", 0)};
        }
        @media (min-width: 768px) {
            margin-top: 72px;
            .text {
                margin-top: 32px;
            }
            .sectonBox {
                margin-top: 60px;
            }
            .second,
            .third {
                margin-top: 44px;
            }
        }
        @media (min-width: 1440px) {
            width: 50%;
            margin-top: 62px;
        }
    }
`;
const AdWrap = styled.div`
    display: none;
    @media (min-width: 1440px) {
        display: block;
        margin-top: 120px;
        border-top: 4px solid #000000;
        border-left: 4px solid #000000;
        border-right: 4px solid #000000;
        box-sizing: border-box;
        width: 100%;
        background-color: #fdd800;
        height: 366px;
    }
`;
const ListWrap = styled.div`
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    border-bottom: 4px solid #000000;

    @media (min-width: 768px) {
        margin-top: 120px;
    }
    @media (min-width: 1440px) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0px;
    }

    .listbox {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 4px solid #000000;
        border-left: 4px solid #000000;
        border-right: 4px solid #000000;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;

        @media (min-width: 1440px) {
            width: 50%;

            .first,
            .third {
                border-left: none;
            }
        }

        .listImg {
            position: absolute;
            opacity: 0;
        }
        @keyframes growRightCircle {
            0% {
                transform: scale(0.5);
            }
            100% {
                transform: scale(1);
            }
        }

        @keyframes growLeftCircle {
            0% {
                transform: scale(0.5);
            }
            100% {
                transform: scale(1);
            }
        }

        @keyframes growRightRed {
            0% {
                transform: scale(0.5) rotate(0deg);
            }
            100% {
                transform: scale(1) rotate(-8deg);
            }
        }

        @keyframes growLeftRed {
            0% {
                transform: scale(0.5) rotate(0deg);
            }
            100% {
                transform: scale(1) rotate(20deg);
            }
        }

        @keyframes growRightGreen {
            0% {
                transform: scale(0.5) rotate(0deg);
            }
            100% {
                transform: scale(1) rotate(-8deg);
            }
        }

        @keyframes growLeftGreen {
            0% {
                transform: scale(0.5) rotate(0deg);
            }
            100% {
                transform: scale(1) rotate(17deg);
            }
        }

        @keyframes growRightRect {
            0% {
                transform: scale(0.5) rotate(0deg);
            }
            100% {
                transform: scale(1) rotate(-8deg);
            }
        }

        @keyframes growLeftRect {
            0% {
                transform: scale(0.5) rotate(0deg);
            }
            100% {
                transform: scale(1) rotate(17deg);
            }
        }
        /* .rightCircle,
        .leftCircle,
        .rightRed,
        .leftRed,
        .rightGreen,
        .leftGreen,
        .rightRect,
        .leftRect {
            transition: transform 0.5s ease-in-out;
        } */
        .rightCircle {
            width: 65px;
            top: 22px;
            right: 2%;
            @media (min-width: 768px) {
                width: 76px;
                top: 41px;
            }
        }
        .leftCircle {
            right: 80vw;
            top: 35px;
            width: 135px;
            @media (min-width: 768px) {
                top: 49px;
                width: 278px;
            }
            @media (min-width: 1440px) {
                right: 36vw;
            }
        }
        .rightRed {
            width: 65px;
            top: 30px;
            right: 2%;
            @media (min-width: 768px) {
                width: 76px;
                top: 50px;
            }
        }
        .leftRed {
            right: 71vw;
            top: -37px;
            width: 166px;
            @media (min-width: 768px) {
                right: 79vw;
                top: -51px;
                width: 226px;
            }
            @media (min-width: 1440px) {
                right: 38vw;
            }
        }
        .rightGreen {
            width: 65px;
            top: 30px;
            right: 2%;
            transform: rotate(-8deg);
            @media (min-width: 768px) {
                width: 76px;
                top: 50px;
            }
        }
        .leftGreen {
            right: 71vw;
            top: 25px;
            width: 166px;
            @media (min-width: 768px) {
                right: 78vw;
                width: 253px;
            }
            @media (min-width: 1440px) {
                right: 36vw;
            }
        }
        .rightRect {
            width: 65px;
            top: 30px;
            right: 2%;
            @media (min-width: 768px) {
                width: 76px;
                top: 50px;
            }
        }
        .leftRect {
            right: 84vw;
            top: 35px;
            width: 130px;
            @media (min-width: 768px) {
                top: 49px;
                width: 188px;
            }
            @media (min-width: 1440px) {
                right: 40vw;
            }
        }

        &:hover {
            background-color: #000;
            color: #f9f8f8;
            .rightCircle {
                animation: growRightCircle 0.2s ease-in-out forwards;
            }
            .leftCircle {
                animation: growLeftCircle 0.2s ease-in-out forwards;
            }
            .rightRed {
                animation: growRightRed 0.2s ease-in-out forwards;
            }
            .leftRed {
                animation: growLeftRed 0.2s ease-in-out forwards;
            }
            .rightGreen {
                animation: growRightGreen 0.2s ease-in-out forwards;
            }
            .leftGreen {
                animation: growLeftGreen 0.2s ease-in-out forwards;
            }
            .rightRect {
                animation: growRightRect 0.2s ease-in-out forwards;
            }
            .leftRect {
                animation: growLeftRect 0.2s ease-in-out forwards;
            }
            .listImg {
                opacity: 1;
            }
            .right,
            .left {
                color: #f9f8f8;
            }
        }
        .left {
            margin-top: 24px;
            ${applyTypography("primary", 1)};
            @media (min-width: 768px) {
                margin-top: 36px;
            }
        }
        .right {
            margin-top: 18px;
            margin-bottom: 24px;
            ${applyTypography("primary", 3)};
            color: #c7c7c7;
            z-index: 10;
            @media (min-width: 768px) {
                margin-bottom: 32px;
            }
        }
    }

    @media (min-width: 1440px) {
        .first,
        .third {
            border-right: none;
        }
    }
`;

const SbaWrap = styled.div`
    background-color: #036eb8;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;

    .wrapInner {
        width: 92%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .logo {
        margin-top: 60px;
        width: 163px;
    }

    .title {
        margin-top: 32px;
        ${applyTypography("primary", 4)};
        color: #f9f8f8;
    }
    .text {
        margin-top: 32px;
        ${applyTypography("secondary", 1)};
        color: #f9f8f8;
        text-align: center;
        margin-bottom: 120px;
        line-height: 22px;
        @media (min-width: 768px) {
            line-height: 26px;
        }
    }
    .tree {
        position: absolute;
        bottom: 0px;
    }
    .leftTree {
        left: -100px;
        @media (min-width: 768px) {
            left: 40px;
        }
        @media (min-width: 1440px) {
            left: 180px;
        }
    }
    .rightTree {
        right: -100px;
        @media (min-width: 768px) {
            right: 25px;
        }
        @media (min-width: 1440px) {
            right: 160px;
        }
    }
    .cloud {
        position: absolute;
    }
    .leftCloud {
        left: -100px;
        width: 152px;
        top: 80px;
        @media (min-width: 768px) {
            left: -103px;
            width: 280px;
            top: 56px;
        }
        @media (min-width: 1440px) {
            left: 210px;
            top: 69px;
        }
    }
    .rightCloud {
        right: -100px;
        width: 126px;
        top: 225px;
        @media (min-width: 768px) {
            right: -66px;
            width: 172px;
            top: 214px;
        }
        @media (min-width: 1440px) {
            right: 190px;
            top: 265px;
        }
    }
    .tempcloud {
        @media (min-width: 768px) {
            display: none;
        }
    }
`;
/* width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 120px;
    background: conic-gradient(from 0.8turn, rgba(255, 14, 20, 1) 0%, rgba(5, 5, 5, 1) 60%, rgba(255, 14, 20, 1) 100%);
    white-space: pre-wrap;

    @media (max-width: 550px) {
        padding-bottom: 72px;
    }

    .left {
        position: relative;
        top: -190px;
        left: 0;
        width: 50%;

        div {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            width: 962px;
            object-fit: cover;
        }
    }

    .right {
        width: 50%;
        color: white;
        padding-top: 140px;

        h1 {
            font-size: 3rem;
            font-family: var(--font--bold);
        }

        .text {
            font-size: 1.125rem;
            line-height: 28.8px;
            padding-right: 30px;
        }

        section {
            .title {
                font-size: 0.875rem;
                margin: 0px;
            }

            .num {
                font-size: 3.875rem;
                padding-top: 10px;
                margin: 0;
                font-weight: 600;
                font-family: var(--font--bold);
            }

            &:nth-child(3) {
                margin-top: 100px;
            }
            &:nth-child(4) {
                margin-top: 44px;
            }
            &:nth-child(5) {
                margin-top: 44px;
            }
        }

        .visible {
            visibility: visible;
        }

        .first.animate__animated {
            animation-delay: 0.3s;
        }

        .second.animate__animated {
            animation-delay: 0.5s;
        }

        .third.animate__animated {
            animation-delay: 0.7s;
        }

        .custom-fadeInUp {
            animation: fadeInUp 1s ease forwards;
            transform: translateY(15%);
        }
        @keyframes fadeInUp {
            from {
                opacity: 0;
                transform: translateY(15%);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    @media (max-width: 820px) {
        width: 100%;
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .left {
            width: 100%;

            div {
                width: 962px;
                object-fit: cover;
            }
        }
        .right {
            width: 90%;
            padding-top: 600px;
        }
    }

    @media (max-width: 550px) {
        .left {
            top: -140px;

            div {
                width: 678px;
            }
        }

        .right {
            padding-top: 460px;

            h1 {
                font-size: 26px;
                margin: 0px;
            }

            .text {
                font-size: 16px;
                line-height: 25.6px;
                padding-right: 0px;
                display: inline-block;
                text-align: start;
                margin: 0;
                padding-top: 44px;
            }
            section {
                &:nth-child(3) {
                    margin-top: 60px;
                }
                .num {
                    font-size: 32px;
                }
            }
        }
    }

    @media (max-width: 350px) {
        height: 1300px;
    }

    @media (max-width: 295px) {
        height: 1400px;
    } */

/* width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;

    .listbox {
        width: 100%;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1.5px solid black;
        position: relative;
        overflow: hidden;
        visibility: hidden;

        .left {
            width: 50%;
            font-weight: 700;
            font-size: 48px;
            margin-right: 100px;
            text-align: center;
            font-family: var(--font--bold);
        }

        .right {
            width: 50%;
            position: relative;
            padding-right: 30px;

            img {
                opacity: 0;
                visibility: hidden;
                transform: translateX(0);
                transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
                z-index: 1;
                position: absolute;
                bottom: 0;
                left: 0;
            }

            p {
                font-size: 30px;
                color: rgba(199, 199, 199, 1);
                font-family: var(--font--bold);
                position: relative;
                visibility: visible;
                opacity: 1 !important;
                z-index: 99;
            }
        }
    }

    .listbox:hover {
        background: black;
        transition: 0.5s;

        .left,
        .right p {
            color: white;
        }

        &::before {
            content: "";
            display: block;
            background-image: inherit;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            transition: transform 0.3s ease;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
        }
    }

    .listbox .right img {
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
    }

    .listbox:hover .right img {
        opacity: 1;
        visibility: visible;
        transform: translateX(-20px);
    }

    .listbox:nth-of-type(1) .right img {
        top: -150px;
        left: -45px;
    }

    .listbox:nth-of-type(2) .right img {
        top: -240px;
        left: -20px;
    }

    .listbox:nth-of-type(3) .right img {
        top: -200px;
        left: -100px;
    }

    .listbox:nth-of-type(4) .right img {
        top: -150px;
        left: -40px;
    }

    .first.animate__animated {
        animation-delay: 0.2s;
    }

    .second.animate__animated {
        animation-delay: 0.3s;
    }

    .third.animate__animated {
        animation-delay: 0.4s;
    }

    .fourth.animate__animated {
        animation-delay: 0.5s;
    }

    .visible {
        visibility: visible;
    }

    .custom-fadeInUp {
        animation: fadeInUp 1s ease forwards;
        transform: translateY(15%);
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(15%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: 820px) {
        .listbox {
            position: relative;
            height: 208px;
            pointer-events: none;
            display: flex;

            .left {
                margin-top: 55px;
            }

            .right {
                width: 100%;
                p {
                    width: 100%;
                    color: black;
                    position: absolute;
                    top: -10px;
                    left: 0;
                    padding-left: 2rem;
                }

                img {
                    width: 150px;
                    opacity: 1;
                    visibility: visible;
                }
            }

            .left {
                width: 100%;
                position: absolute;
                top: -8px;
                left: 0;
                text-align: left;
                padding-left: 2rem;
            }
        }
        .listbox:nth-of-type(1) .right img {
            top: -75px;
            left: 75%;
        }

        .listbox:nth-of-type(2) .right img {
            top: -70px;
            left: 75%;
        }

        .listbox:nth-of-type(3) .right img {
            top: -75px;
            left: 75%;
        }

        .listbox:nth-of-type(4) .right img {
            top: -80px;
            left: 77%;
        }
    }

    @media (max-width: 560px) {
        .listbox {
            height: 144px;

            .left {
                font-size: 26px;
                top: -15px;
            }

            .right {
                p {
                    font-size: 16px;
                    top: -5px;
                }

                img {
                    width: 120px;
                }

                .second {
                    width: 70px;
                }
            }
        }

        .listbox:nth-of-type(1) .right img {
            top: -60px;
            left: 70%;
        }

        .listbox:nth-of-type(2) .right img {
            top: -55px;
            left: 70%;
        }

        .listbox:nth-of-type(3) .right img {
            top: -60px;
            left: 70%;
        }

        .listbox:nth-of-type(4) .right img {
            top: -60px;
            left: 72%;
        }
    }

    @media (max-width: 430px) {
        .listbox {
            .left {
                left: -20px;
            }

            .right {
                p {
                    left: -20px;
                }

                img {
                    width: 85px;
                }
            }
        }

        .listbox:nth-of-type(1) .right img {
            top: -45px;
            left: 74%;
        }

        .listbox:nth-of-type(2) .right img {
            top: -40px;
            left: 75%;
        }

        .listbox:nth-of-type(3) .right img {
            top: -45px;
            left: 74%;
        }

        .listbox:nth-of-type(4) .right img {
            top: -45px;
            left: 76%;
        }
    } */

/* width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    background-color: #000;
    color: #fff;
    white-space: pre-wrap;

    img {
        width: 163px;
        max-width: 300px;
        margin-bottom: 30px;
    }

    b {
        font-size: 22px;
        margin-bottom: 20px;
        font-family: var(--font--bold);
        text-align: center;
        line-height: 25px;
    }

    p {
        max-width: 1000px;
        text-align: center;
        margin-bottom: 0px;
        line-height: 1.8rem;
        padding: 0 30px;
    }

    @media (max-width: 820px) {
        p {
            max-width: 90%;
        }
    }

    @media (max-width: 550px) {
        b {
            font-size: 16px;
        }

        p {
            font-size: 14px;
        }
    } */
// `;
