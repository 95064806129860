import Navibar from "../../components/navigation/Navibar";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import leftBtn from "../../assets/images/main/ico-roundArrow-left.svg";
import RightBtn from "../../assets/images/main/ico-roundArrow-right.svg";
import Footer from "../../components/footer/Footer";
import round from "../../assets/images/seoulcon/img-round.svg";
import triangle from "../../assets/images/program/img-triangle.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../constants";
import { useEffect, useRef, useState } from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import leftArrowHover from "../../assets/images/seoulcon/ico-roundArrow-left-hover.svg";
import rightArrowHover from "../../assets/images/seoulcon/ico-roundArrow-right-hover.svg";
import { Helmet } from "react-helmet";
import Popup from "../../components/Popup";
import { formatDate } from "../../common/util-function";
import mainBack from "../../assets/images/main/main-back.png";
import rightArrow from "../../assets/images/main/rightarrow.png";
import mainLogo from "../../assets/images/main/mainlogo.png";
import styled, { keyframes } from "styled-components";
import Wraps from "../../components/Wraps";
import PaddingWraps from "../../components/PaddingWraps";
import { applyTypography } from "../../styles/mixins";
import MainNavi from "../../components/navigation/MainNavi";
import smile from "../../assets/images/program/char-smile.png";
import charStay from "../../assets/images/program/char-stay.svg";
import mainImage from "../../assets/images/main/left-image.svg";
import MainFooter from "../../components/footer/MainFooter";
import mainBtn from "../../assets/images/main/main.svg";
import mainVideo from "../../assets/video/seoul-2024.mp4";

// interface ArrowProps {
//     className?: string;
//     style?: React.CSSProperties;
//     onClick?: () => void;
// }

interface Influencer {
    nameKo: string;
    nameEn: string;
    sns: string;
    follower: string;
    promotion: boolean;
    link: string;
    id: number;
    imagePath: string;
}

interface Video {
    nameKo: string;
    nameEn: string;
    titleKo: string;
    titleEn: string;
    imagePath: string;
}

export interface MainContent {
    influKo: { title: string; content: string };
    influEn: { title: string; content: string };
    programKo: { title: string; content: string };
    programEn: { title: string; content: string };
    imagePath1: { ko: string; en: string; imagePath: string };
    imagePath2: { ko: string; en: string; imagePath: string };
    imagePath3: { ko: string; en: string; imagePath: string };
    imagePath4: { ko: string; en: string; imagePath: string };
    imagePath5: { ko: string; en: string; imagePath: string };
    textKo: { title: string; content: string };
    textEn: { title: string; content: string };
}

const Main = () => {
    const [posts, setPosts] = useState([]);
    const [currentBanner, setCurrentBanner] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [scrollLeft, setScrollLeft] = useState(0);
    const imgBoxRef = useRef<HTMLDivElement>(null);
    const [isScrolling, setIsScrolling] = useState(true);
    const [dragStartX, setDragStartX] = useState(0);
    const sliderRef = useRef<Slider | null>(null);
    const [animate, setAnimate] = useState(false);
    const [hasAnimated, setHasAnimated] = useState(false);
    const [banners, setBanners] = useState([
        {
            img: "",
            text: "",
            h1: "",
            link: "",
            imgMo: "",
        },
    ]);
    const [allList, setAllList] = useState<Influencer[]>([]);
    const [video, setVideo] = useState<Video>();
    const [mains, setMains] = useState<MainContent>();
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [isDrag, setIsDrag] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 550);
    const [metaData, setMetaData] = useState({ titleKo: "", titleEn: "", ogImage: "" });

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
        console.log(process.env.NODE_ENV);
        const fetchPosts = async () => {
            try {
                const seo = await axios.get(`${APIURL}/manage/seo`);
                const seoData = seo.data.data;
                setMetaData({
                    titleKo: seoData.textKo.title,
                    titleEn: seoData.textEn.title,
                    ogImage: seoData.imagePath1.imagePath,
                });

                const response = await axios.get(`${APIURL}/board`);
                const res = await axios.get(`${APIURL}/manage/slide/list`);
                setPosts(response.data.data);
                const transformedData = res.data.data.map((item: any) => ({
                    img: `${APIURL}/${item.imagePath}`,
                    imgMo: item.imagePathMo,
                    text: activeLanguage === "en" ? item.titleEn : item.titleKo,
                    h1: activeLanguage === "en" ? item.nameEn : item.nameKo,
                    link: activeLanguage === "en" ? item.linkEn : item.linkKo,
                }));
                setBanners(transformedData);

                // const promotion = await axios.get(`${APIURL}/influencer/promotion`);
                // const allList = await axios.get(`${APIURL}/influencer/allList`);
                const video = await axios.get(`${APIURL}/manage/video`);
                const mains = await axios.get(`${APIURL}/manage/main`);
                //console.log(mains.data.data);

                setVideo(video.data.data);
                // setAllList([...promotion.data.data, ...allList.data.data]);
                setMains(mains.data.data);
            } catch (error) {
                console.error("Error fetching the posts:", error);
            }
        };
        fetchPosts();
    }, [activeLanguage]);

    useEffect(() => {
        const handleResize = () => {
            const isMobileView = window.innerWidth <= 550;
            setIsMobile(isMobileView);
        };

        window.addEventListener("resize", handleResize);

        // 첫 마운트시에도 실행
        handleResize();

        // 컴포넌트 언마운트시 이벤트 리스너 제거
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [isVideoVisible, setIsVideoVisible] = useState(false);

    const handleButtonClick = () => {
        setIsVideoVisible(true);
    };

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{metaData.titleEn}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{metaData.titleKo}</title>
                </Helmet>
            )}
            <Popup />

            <MainNavi />
            <Wrap>
                <MainBoxContainer>
                    <MainContainer>
                        <LeftMenu>
                            <div className="blue-back"></div>
                            <img className="mainLogo" src={mainImage} alt="mainLogo" />
                        </LeftMenu>
                        <RightMenu>
                            <MainWrap>
                                <CarouselContainer>
                                    <CarouselWrapper isVideoVisible={isVideoVisible}>
                                        <MainBack>
                                            <img className="mainBack" src={mainBtn} alt="mainBack" />
                                            <Content>
                                                {activeLanguage === "en"
                                                    ? "Welcome the New Year in Seoul with worldwide influencers"
                                                    : "전 세계 1인 미디어와\n서울의 새해를 맞이하세요"}
                                            </Content>
                                            <PlayButton onClick={handleButtonClick}>click</PlayButton>
                                        </MainBack>

                                        <MainBack>
                                            <VideoContainer>
                                                <video
                                                    playsInline
                                                    autoPlay
                                                    loop
                                                    muted
                                                    src={mainVideo}
                                                    // src={`${APIURL}/${video?.imagePath}`}
                                                ></video>
                                            </VideoContainer>
                                        </MainBack>
                                    </CarouselWrapper>
                                </CarouselContainer>
                                <SubTitle>
                                    {activeLanguage === "en"
                                        ? `Dive in and explore 2024 SEOULCon programs now!`
                                        : `2024 서울콘의 다양한 프로그램을 만나보세요`}
                                </SubTitle>
                                <ProgramWrap>
                                    <img className="stay" src={charStay} alt="stay" />
                                    <StyleLinkPc
                                        to={`/${activeLanguage}/contents`}
                                        mainHover={`${APIURL}/${mains?.imagePath3.imagePath}`}
                                    >
                                        <Program>
                                            <div className="left">
                                                <div className="num">01</div>
                                                <div className="title">
                                                    {activeLanguage === "en"
                                                        ? mains?.imagePath3.en
                                                        : mains?.imagePath3.ko}
                                                </div>
                                            </div>
                                            <div className="right">
                                                <img className="arrow" src={rightArrow} alt="arrow" />
                                            </div>
                                        </Program>
                                    </StyleLinkPc>
                                    <StyleLinkPc
                                        to={`/${activeLanguage}/festival`}
                                        mainHover={`${APIURL}/${mains?.imagePath1.imagePath}`}
                                    >
                                        <Program heightbool={activeLanguage === "en" ? true : false}>
                                            <div className="left">
                                                <div className="num">02</div>
                                                <div className="title">
                                                    {activeLanguage === "en"
                                                        ? mains?.imagePath1.en
                                                        : mains?.imagePath1.ko}
                                                </div>
                                            </div>
                                            <div className="right">
                                                <img className="arrow" src={rightArrow} alt="arrow" />
                                            </div>
                                        </Program>
                                    </StyleLinkPc>
                                    <StyleLinkPc
                                        to={`/${activeLanguage}/performance`}
                                        mainHover={`${APIURL}/${mains?.imagePath4.imagePath}`}
                                    >
                                        <Program>
                                            <div className="left">
                                                <div className="num">03</div>
                                                <div className="title">
                                                    {activeLanguage === "en"
                                                        ? mains?.imagePath4.en
                                                        : mains?.imagePath4.ko}
                                                </div>
                                            </div>
                                            <div className="right">
                                                <img className="arrow" src={rightArrow} alt="arrow" />
                                            </div>
                                        </Program>
                                    </StyleLinkPc>
                                    <StyleLinkHalf
                                        to={`/${activeLanguage}/conference`}
                                        mainHover={`${APIURL}/${mains?.imagePath2.imagePath}`}
                                    >
                                        <Program>
                                            <div className="left">
                                                <div className="num">04</div>
                                                <div className="title">
                                                    {activeLanguage === "en"
                                                        ? mains?.imagePath2.en
                                                        : mains?.imagePath2.ko}
                                                </div>
                                            </div>
                                            <div className="right">
                                                <img className="arrow" src={rightArrow} alt="arrow" />
                                            </div>
                                        </Program>
                                    </StyleLinkHalf>

                                    <StyleLinkHalf
                                        to={`/${activeLanguage}/wintafesta`}
                                        mainHover={`${APIURL}/${mains?.imagePath5.imagePath}`}
                                    >
                                        <Program>
                                            <div className="left">
                                                <div className="num">05</div>
                                                <div className="title">
                                                    {activeLanguage === "en"
                                                        ? mains?.imagePath5.en
                                                        : mains?.imagePath5.ko}
                                                </div>
                                            </div>
                                            <div className="right">
                                                <img className="arrow" src={rightArrow} alt="arrow" />
                                            </div>
                                        </Program>
                                    </StyleLinkHalf>
                                </ProgramWrap>
                            </MainWrap>
                        </RightMenu>
                    </MainContainer>
                    <MainBottom>
                        <MainFooter />
                    </MainBottom>
                </MainBoxContainer>
            </Wrap>
        </>
    );
};

export default Main;

const Wrap = styled(Wraps)``;
const MainBoxContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
`;
const MainContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
`;
const LeftMenu = styled.div`
    width: 254px;
    background-color: #006934;
    border: 4px #000 solid;
    display: none;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-sizing: border-box;

    .blue-back {
        position: absolute;
        height: 290px;
        background: #036eb8;
        width: 254px;
        border: 4px solid #000;
        box-sizing: border-box;
    }

    .mainLogo {
        margin-top: 109px;
        z-index: 1;
    }

    @media (min-width: 1200px) {
        display: flex;
    }
`;
const RightMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: 1200px) {
        width: calc(100% - 254px);
    }
`;
const MainWrap = styled(PaddingWraps)`
    padding-top: 84px;
    margin: 0 auto;
    @media (min-width: 768px) {
        padding-top: 108px;
    }
`;
const MainBottom = styled.div``;
const MainBack = styled.div`
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1 0 50%; /* 각 슬라이드가 전체 너비의 절반을 차지 */
    width: 100%;

    @media (min-width: 768px) {
        margin-top: 40px;
    }
    img {
        width: 100%;
        border-top: solid 4px #000000;
        border-left: solid 4px #000000;
        border-right: solid 4px #000000;
        box-sizing: border-box;
    }
`;

const PlayButton = styled.button`
    opacity: 0;
    position: absolute;
    width: 75px;
    height: 75px;
    cursor: pointer;
    border: none;
    background: transparent;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (min-width: 768px) {
        width: 100px;
        height: 100px;
    }
`;

const CarouselContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
`;

const CarouselWrapper = styled.div<{ isVideoVisible: boolean }>`
    display: flex;
    width: 200%; /* 두 개의 슬라이드 크기를 고려 */
    transform: ${({ isVideoVisible }) => (isVideoVisible ? "translateX(-50%)" : "translateX(0)")};
    transition: transform 0.5s ease;
`;
const VideoContainer = styled.div`
    width: 100%;
    height: 100%;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const Content = styled.p`
    ${applyTypography("primary", 0)};
    color: #ffffff;
    background-color: #c30f23;
    padding: 18px 7.5px;
    text-align: center;
    line-height: 42px;
    border-bottom: solid 4px #000000;
    border-left: solid 4px #000000;
    border-right: solid 4px #000000;
    white-space: pre-line;
    margin-top: -1px;
    @media (min-width: 768px) {
        line-height: 70px;
    }
`;

const SubTitle = styled.div`
    margin-top: 60px;
    ${applyTypography("primary", 1)};
    line-height: 36px;
    max-width: 289px;
    min-width: 289px;
    word-wrap: break-word;
    white-space: normal;

    @media (min-width: 768px) {
        width: 92%;
        max-width: 92%;
        line-height: 60px;
        margin-top: 100px;
    }
`;
const ProgramWrap = styled.div`
    margin-top: 40px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px;
    position: relative;
    .stay {
        display: none;
    }
    @media (min-width: 1200px) {
        .stay {
            display: block;
            position: absolute;
            z-index: 10;
            width: 160px;
            top: -183px;
            right: 60px;
        }
    }

    @media (min-width: 1200px) {
        flex-direction: row;
    }
`;

const Program = styled.div<{ heightbool?: boolean }>`
    border: 4px solid black;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    position: relative;
    @media (min-width: 1200px) {
        height: ${({ heightbool }) => (heightbool ? "88px" : "auto")};
    }

    .left {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .num {
        ${applyTypography("primary", 4)};
        color: #c30d23;
    }
    .title {
        ${applyTypography("primary", 3)};
        margin-top: 10px;
    }
    .arrow {
        width: 10px;
        @media (min-width: 768px) {
            width: 16px;
        }
    }
`;

const StyledLink = styled(Link)<{ mainHover?: string; color?: string }>`
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${({ mainHover }) => mainHover});
        background-size: cover;
        background-position: center;
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: 0;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0); /* 기본 투명 */
        transition: background-color 0.3s ease;
        z-index: 1;
    }

    &:hover::before {
        opacity: 1;
    }
    &:hover::after {
        background-color: rgba(0, 0, 0, 0.6); /* 어두운 오버레이 */
    }
    &:hover .num,
    &:hover .title {
        color: ${({ color }) => (color ? color : "#ffffff")};
        position: relative;
        z-index: 1;
    }
`;
const StyleLinkPc = styled(StyledLink)`
    @media (min-width: 1200px) {
        width: 32%;
    }
`;
const StyleLinkHalf = styled(StyledLink)`
    @media (min-width: 1200px) {
        width: 48.55%;
    }
`;
